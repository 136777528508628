import { useTranslation } from "next-i18next"
import { useEffect } from "react"
import { useRouter } from "next/router"
import { getBlogPosts } from "../utils/blog"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"

import Header from "../components/Header"
import Hero from "../components/local/Home/Hero"
import Testimonials from "../components/local/Home/Testimonials"
import HowWorks from "../components/local/Home/HowWorks"
import Blog from "../components/local/Blog/Blog"
import CTA from "../components/local/Home/CTA"
import FAQs from "../components/local/Home/FAQs"
import Footer from "../components/local/Footer"
import styles from "./index.module.scss"
import { urlContainsLocale } from "../utils/browser"

export async function getStaticProps({ locale, locales }) {
  const language = locale.substring(0, 2)

  const categories = {
    en: 19,
    es: 21,
    pt: 17,
    de: 35,
    fr: 36,
    it: 37,
  }

  const category = categories[language] || categories.en

  const { posts, postsUnordered } = await getBlogPosts({
    locale,
    params: `?categories=${category}`,
  })

  return {
    props: {
      ...(await serverSideTranslations(language, ["common"])),
      posts,
      locales,
      postsUnordered,
    },
  }
}

async function fetchCheckUser({ language, country }) {
  await fetch(
    "/api/check-user-status?language=" + language + "&country=" + country
  )
}

function Home({ locales, ...props }) {
  const router = useRouter()

  console.log({ props })
  const [language, country] = router.locale.split("-")

  useEffect(() => {
    // Define your supported locales

    // Use window.location.pathname to get the current URL
    const currentUrl = window.location.pathname

    // Check if the current URL contains any of the supported locales
    if (!urlContainsLocale(currentUrl, locales)) {
      // Redirect the user to the localized URL
      router.push(`/en-US${currentUrl}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchCheckUser({ language, country })
  }, [])

  const { t } = useTranslation("common")
  return (
    <div className={styles.MainWrapper}>
      <Header
        logoUrl="/assets/logo-black.png"
        pageTitle={t("HOME_TITLE")}
        pageDescription={t("HOME_DESCRIPTION")}
        fixedHeader={false}
        transparent={true}
        displayProductHunt={true}
      />
      <Hero />
      <HowWorks />
      <Testimonials />
      <CTA />
      <Blog posts={props.posts} />
      <FAQs />
      <Footer />
    </div>
  )
}

export default Home
